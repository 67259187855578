import React from 'react'

const EditTour = () => {
  return (
    <div>
      editttttttttttttttttt
    </div>
  )
}

export default EditTour
