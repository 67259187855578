import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Button, Spinner } from "react-bootstrap";
import Name from '../../Components/Create/Name';
import SetUp from '../../Components/Create/SetUp';
import "../../Components/Create/Create.css"
import Teams from '../../Components/Create/Teams';
import Members from '../../Components/Create/Members';
import Rules from '../../Components/Create/Rules';
import Species from '../../Components/Create/Species';
import Submission from '../../Components/Create/Submission';
import CreateSuccess from '../../Components/Create/CreateSuccess';


const CreateTournament = () => {
    const navigate = useNavigate()

    useEffect(() => {
        // Retrieve the stored page from localStorage
        const storedPage = localStorage.getItem("page");
        // If a page is stored, navigate to that page, else navigate to the create page
        if (storedPage) {
            navigate(storedPage);
        } else {
            navigate('/user-login/create'); // Default to the create page
        }
    }, [navigate]);

    return (
        <>
            <div className='mb-5' style={{ display: "flex", alignItems: "center",justifyContent:"center" }}>
                <h3 style={{ fontWeight: 600 }}>Create Tournament</h3>
            </div>

            <Routes>
                <Route path="/" element={<Name />} />
                <Route path="/setup" element={<SetUp />} />
                <Route path="/species" element={<Species />} />
                <Route path="/teams" element={<Teams/>} />
                <Route path="/members" element={<Members/>} />
                <Route path="/rules" element={<Rules/>} />
                <Route path="/submission" element={<Submission/>} />
                <Route path="/create-success" element={<CreateSuccess/>} />
            </Routes>
        </>
    )
}

export default CreateTournament;