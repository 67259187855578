import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import "../publish.css";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import appUrl from '../../../appUrl';

const Report = () => {
  const navigate = useNavigate();
  const [report, setReport] = useState([]);
  const AppUrl = appUrl();

  useEffect(() => {
    getReportList();
  }, []);

  const getReportList = async () => {
    const fishingToken = localStorage.getItem("token");
    const reportID = localStorage.getItem("report");
    try {
      const response = await fetch(`${AppUrl}/api/v1/report?report_id=${reportID}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${fishingToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok && data.data) {
        setReport(data.data);
      } else {
        NotificationManager.error('Failed to fetch report list');
      }
    } catch (error) {
      NotificationManager.error('Error fetching report list');
    }
  };

  console.log(report, "reports")

  return (
    <>
      <NotificationContainer />
      <div className='report-title'>
        <IoArrowBack onClick={() => navigate("/user-login/publish/report")}  className='back-arrow' />
        <h3>Result</h3>
        <p></p>
      </div>

      {report.length > 0 ? (
        <Table striped bordered hover responsive className='mt-5'>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Participant</th>
              <th>Species</th>
              <th>Species Count</th>
              <th>Points</th>
              <th>Guide</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {report.map((rep, index) => (
              <tr key={rep._id}>
                <td>{index + 1}</td>
                <td>{rep.participant_name}</td>
                <td>{rep.species_name || "-"}</td>
                <td>{rep.total_species || "-"}</td>
                <td>{rep.total_points || "-"}</td>
                <td>{rep.guide || "-"}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className='text-center mt-5'>You have not added any Report for this tournament. Add to proceed</p>
      )}
    </>
  );
};

export default Report;
