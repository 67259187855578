import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BallTriangle } from 'react-loader-spinner';
import * as Yup from 'yup';
import appUrl from '../../../appUrl';
import 'react-datepicker/dist/react-datepicker.css';
import { IoIosArrowDown } from "react-icons/io";
import '../../Create/Create.css';
import '../publish.css';

const CreateReport = () => {
  const AppUrl = appUrl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fetchedOption, setFetchedOption] = useState([]);
  const [reportDetails, setReportDetails] = useState({});
  const [isUpdate] = useState(localStorage.getItem("isUpdate"));

  console.log(reportDetails, "reports")
  // console.log(reportDetails.guided, "guided")

  const schema = Yup.object().shape({
    report_name: Yup.string().required('Report Name is required'),
    participants: Yup.string().required('Participants selection is required'),
    // range: Yup.string().required('Range selection is required'),
    // guided: Yup.string().required('Guided selection is required'),
    // // tackle_type: Yup.string().required('Tackle type selection is required'),
    species: Yup.string().required('Species selection is required'),
    // species_number: Yup.number().required('Number of species is required').positive().integer(),
    species_stat: Yup.string().required('Species stats selection is required'),
    // first_catch: Yup.boolean(), // Assuming this will be a boolean value
    // report_visibility: Yup.string().required('Report visibility is required')
  });

  const handleSubmission = async (values) => {
    setLoading(true);
    const fishingToken = localStorage.getItem('token');
    const tournament_id = localStorage.getItem('tournament');
    const method = isUpdate === "true" ? 'PUT' : 'POST';
    const url = isUpdate === "true" ? `${AppUrl}/api/v1/report/${reportDetails._id}` : `${AppUrl}/api/v1/report/create`;

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          tournament: tournament_id, // Add tournament ID
          report_visibility: values.report_visibility === "Private" ? false : true
        }),
      });

      const data = await response.json();
      if (response.ok && data.status === true) {
        // NotificationManager.success(data.message);
        localStorage.setItem("report", data.report._id);
        navigate("/user-login/publish/result");
      } else {
        NotificationManager.error(data.message || 'Failed to submit report.');
        console.error('Error:', data.message);
      }
    } catch (error) {
      NotificationManager.error(error.message || 'An unexpected error occurred.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getReportDetails = async () => {
      if (isUpdate) {
        const fishingToken = localStorage.getItem('token');
        const reportID = localStorage.getItem("tournament");
        try {
          const response = await fetch(`${AppUrl}/api/v1/tournament/report?reportId=${reportID}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${fishingToken}`,
              'Content-Type': 'application/json',
            }
          });
          const data = await response.json();
          if (data.code === 200 || data.status === true) {
            setReportDetails(data.data);
          } else {
            console.error('Failed to fetch report details');
          }
        } catch (error) {
          console.error('Error fetching report details:', error);
        }
      }
    };
    getReportDetails();
  }, [isUpdate]);

  const initialValues = isUpdate === "true"
    ? {
      report_name: reportDetails.report_name || '',
      participants: reportDetails.participants || '',
      range: reportDetails.range || '',
      guided: reportDetails.guided || "All",
      tackle_type: reportDetails.tackle_type || '',
      species: reportDetails.species || '',
      species_number: reportDetails.species_number || '',
      species_stat: reportDetails.species_stat || '',
      first_catch: reportDetails.first_catch || false,
      report_visibility: reportDetails.report_visibility || ''
    }
    : {
      report_name: '',
      participants: '',
      range: '',
      guided: '',
      tackle_type: '',
      species: '',
      species_number: '',
      species_stat: '',
      first_catch: false,
      report_visibility: ''
    };

  useEffect(() => {
    const getSelectionOptions = async () => {
      const fishingToken = localStorage.getItem('token');
      const tournamentId = localStorage.getItem("tournament");
      try {
        const response = await fetch(`${AppUrl}/api/v1/report/details?tournament_id=${tournamentId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${fishingToken}`,
            'Content-Type': 'application/json',
          }
        });
        const data = await response.json();
        if (data.code === 200 || data.status === true) {
          setFetchedOption(data.data);
        } else {
          console.error('Failed to fetch selection options');
        }
      } catch (error) {
        console.error('Error fetching selection options:', error);
      }
    };
    getSelectionOptions();
  }, [AppUrl]);

  const getSelectionOptions = async () => {
    try {
      const fishingToken = localStorage.getItem('token');
      const tournamentId = localStorage.getItem('tournament');
      const response = await fetch(`${AppUrl}/api/v1/tournament/report/details?tournamentId=${tournamentId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      if (data.code === 200 || data.status === true) {
        console.log("fetched successfully");
        setFetchedOption(data.data); // Set teams data
      } else {
        console.error('Failed to fetch teams');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getSelectionOptions();
  }, []);

  return (
    <>
      <NotificationContainer />
      <IoArrowBack onClick={() => navigate("/user-login/publish/report")} className='back-arrow' /> 
      <div>
        {loading && (
          <div className='loader-overlay'>
            <BallTriangle color="#00555C" height={80} width={80} />
          </div>
        )}
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmission(values);
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className='mb-3'>
              <div className='details-field mt-3 mb-3'>
                <label htmlFor='report_name'>Report Name</label>
                <Field
                  type='text'
                  id='report_name'
                  name='report_name'
                  autoComplete='off'
                />
                <div className='error-wrapper mb-3'>
                  <ErrorMessage name='report_name' component='div' className='error' />
                </div>
              </div>

              <div className='details-half'>
                <div className="details-field mb-3">
                  <label htmlFor="participants">Participants</label>
                  <Field as="select" name="participants" id="participants" className="form-control">
                    <option value="">Select Participants</option>
                    <option value="angler">Angler</option>
                    <option value="teams">Team</option>
                    <option value="guide">Guided</option>
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="participants" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="range">Range</label>
                  <Field as="select" name="range" id="range" className="form-control">
                    {/* <option value="">Select Range</option> */}
                    <option value="all">All</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="junior">Junior</option>
                    <option value="senior">Senior</option>
                    <option value="youth">Youth</option>
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="range" component="div" className="error-message" />
                  </div>
                </div>
              </div>

              <div className='details-half'>
                <div className="details-field mb-3">
                  <label htmlFor="species">Species</label>
                  <Field as="select" name="species" id="species" className="form-control">
                    <option value="all">All</option>
                    {fetchedOption?.species?.map((spec) => (
                      <option key={spec.id} value={spec.id}>
                        {spec.name}
                      </option>
                    ))}
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="species" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="species_stat">Species stats to use</label>
                  <Field as="select" name="species_stat" id="species_stat" className="form-control">
                    {/* <option value="all">all</option> */}
                    <option value="points">Points</option>
                    <option value="weight">Weight</option>
                    <option value="length">Length</option>
                  </Field>
                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="species_stat" component="div" className="error-message" />
                  </div>
                </div>
              </div>

              <div className='details-half'>
                <div className="details-field mb-3">
                  <label htmlFor="species_number">Quantity per Species</label>
                  <Field type="number" name="species_number" className="form-control" />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="species_number" component="div" className="error-message" />
                  </div>
                </div>

                <div className="details-field mb-3">
                  <label htmlFor="tackle_type">Tackle Type</label>
                  <Field as="select" name="tackle_type" id="tackle_type" className="form-control">
                    {fetchedOption?.fishingStyles?.map((style) => (
                      <option key={style.id} value={style.id}>
                        {style.fishing_style}
                      </option>
                    ))}
                  </Field>

                  <IoIosArrowDown />
                  <div className="error-wrapper mb-3">
                    <ErrorMessage name="tackle_type" component="div" className="error-message" />
                  </div>
                </div>
              </div>

              <div className="details-field mb-3">
                <label htmlFor="guided">Guided</label>
                <Field as="select" name="guided" id="guided" className="form-control">
                  <option value="all">All</option>
                  <option value="guided">Guided</option>
                  <option value="unguided">Unguided</option>
                </Field>
                <IoIosArrowDown />
                <div className="error-wrapper mb-3">
                  <ErrorMessage name="guided" component="div" className="error-message" />
                </div>
              </div>

              {/* <div className='details-field mb-3'>
                <label htmlFor='first_catch'>First Catch</label>
                <Field type='checkbox' id='first_catch' name='first_catch' />
              </div> */}

              <div className="details-field mb-3">
                <label htmlFor="report_visibility">Report Visibility</label>
                <Field as="select" name="report_visibility" id="report_visibility" className="form-control">
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </Field>
                <IoIosArrowDown />
                <div className="error-wrapper mb-3">
                  <ErrorMessage name="report_visibility" component="div" className="error-message" />
                </div>
              </div>

              <tr className='create-report-selection mb-3' >
                <td>
                  <p><span><Field type='checkbox' id='multipliers' name='multipliers' /></span> Use Multipliers </p>
                </td>
                <td>
                  <p><span> <Field type='checkbox' id='first_catch' name='first_catch' /></span> First Catch in on Top for ties </p>
                </td>
                <td>
                  <p><span> <Field type='checkbox' id='largest_species' name='largest_species' /></span> Use only Largest per species Regardless of Tackle type </p>
                </td>
              </tr>

              <Button type='submit' variant="success" disabled={loading} className='next-create-button mb-5'>
                {/* {isUpdate === "true" ? 'Update Report' : 'Create Report'} */}
                Create Report
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateReport;
