import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";
import appUrl from '../../appUrl'; // Corrected duplicate import

const Teams = () => {
    const navigate = useNavigate();
    const AppUrl = appUrl();

    const [teamName, setTeamName] = useState("");
    const [teamPhoto, setTeamPhoto] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null); // For displaying selected image
    const [teamData, setTeamData] = useState([]); // Ensure this is initialized as an empty array
    const [teamsModal, setTeamsModal] = useState(false);

    console.log(selectedImage, "Selected")


    // const handleAddTeams = async () => {
    //     try {
    //         const fishingToken = localStorage.getItem("token");
    //         const tournament = localStorage.getItem("tournament");

    //         const formData = new FormData();

    //         // Create an array of teams
    //         const teams = [];

    //         // Create a single team object with team_photo as a string
    //         const team = {
    //             team_name: teamName,
    //             team_photo: teamPhoto || "" // Ensure it's a string
    //         };

    //         // Add the team to the teams array
    //         teams.push(team);

    //         // Append teams to FormData
    //         formData.append('teams', JSON.stringify(teams)); // Stringify the array

    //         // Fetch request with tournament_id in the query string
    //         const response = await fetch(`${AppUrl}/api/v1/tournament/add-team?tournament_id=${tournament}`, {
    //             method: "POST",
    //             headers: {
    //                 Authorization: `Bearer ${fishingToken}`,
    //                 // Do not set 'Content-Type' when using FormData
    //             },
    //             body: formData
    //         });

    //         const data = await response.json();
    //         if (data.code === 201) {
    //             console.log("Teams created successfully");
    //             handleGetTeams(); // Refresh the team list
    //             setTeamsModal(false);
    //             setTeamName("");
    //             setTeamPhoto(null);
    //             setSelectedImage(null);
    //         } else {
    //             console.error(data.message);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    const handleAddTeams = async () => {
        try {
            const fishingToken = localStorage.getItem("token");
            const tournament = localStorage.getItem("tournament");

            // Construct the payload as JSON
            const payload = {
                teams: [
                    {
                        team_name: teamName,
                        team_photo: teamPhoto || "" // Ensure it's a string URL or empty string
                    }
                ]
            };

            // Perform fetch request with JSON payload
            const response = await fetch(`${AppUrl}/api/v1/tournament/update-team?tournament_id=${tournament}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json' // Set content type to JSON
                },
                body: JSON.stringify(payload) // Stringify the JSON payload
            });

            const data = await response.json();
            if (data.code === 201 || data.status === true) {
                console.log("Teams created successfully");
                handleGetTeams(); // Refresh the team list
                setTeamsModal(false);
                setTeamName("");
                setTeamPhoto(null);
                setSelectedImage(null);
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleDeleteRow = async (team_id) => {
        try {
            const fishingToken = localStorage.getItem("token");
            const tournament = localStorage.getItem("tournament");
            const response = await fetch(`${AppUrl}/api/v1/tournament/${tournament}/team/${team_id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    "Content-Type": "application/json"
                }
            });
            const data = await response.json();
            if (data.status === true) {
                console.log("Team deleted successfully");
                handleGetTeams(); // Refresh the team list
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleGetTeams = async () => {
        try {
            const fishingToken = localStorage.getItem('token');
            const tournamentId = localStorage.getItem("tournament");
            const response = await fetch(`${AppUrl}/api/v1/tournament/all-teams?tournamentId=${tournamentId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.code === 200 || data.status === true) {
                console.log("Teams fetched successfully");
                setTeamData(data.data || []); // Default to an empty array if data.data is undefined
            } else {
                console.error('Failed to fetch teams');
                setTeamData([]); // Reset to empty array on error
            }
        } catch (error) {
            console.error('Error:', error);
            setTeamData([]); // Reset to empty array on error
        }
    };

    useEffect(() => {
        handleGetTeams();
    }, [teamsModal]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) { // 10MB
                alert("File size exceeds 10MB");
                return;
            }
            const blobUrl = URL.createObjectURL(file); // Create blob URL
            setSelectedImage(blobUrl); // Show selected image preview
            setTeamPhoto(blobUrl); // Store blob URL in Formik state
        }
    };


    const handleImageDelete = () => {
        setSelectedImage(null); // Clear selected image preview
        setTeamPhoto(null); // Clear team photo
    };

    return (
        <>
            <div >
                <div className='species-button-group'>
                    <div className='page-title'>Teams</div>
                    <Button
                        type="button"
                        onClick={() => setTeamsModal(true)}
                        className="add-species-button"
                    >
                        Add Teams
                    </Button>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Team Name</th>
                            <th>Team Photo</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(teamData) && teamData.length > 0 ? (
                            teamData.map((team, index) => (
                                <tr key={team._id}>
                                    <td>{index + 1}</td>
                                    <td>{team.team_name}</td>
                                    <td>
                                        {team.team_photo ? (
                                            <img src={team.team_photo} alt='Team' width="50" />
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td>
                                        <FaTimes
                                            onClick={() => handleDeleteRow(team._id)}
                                            style={{ cursor: 'pointer', color: 'red' }}
                                        />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No teams available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <div className="species-button-group">
                    <Button
                        type="button"
                        className="next-create-button"
                        onClick={() => {
                            localStorage.setItem("page", "species");
                            navigate('/user-login/create/species');
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        type="button"
                        className="next-create-button"
                        onClick={() => {
                            localStorage.setItem("page", "members");
                            navigate('/user-login/create/members');
                        }}
                    >
                        Next
                    </Button>
                </div>
            </div>

            <Modal
                show={teamsModal}
                onHide={() => setTeamsModal(false)}
                backdrop="static"
                centered
            >
                <ModalHeader closeButton>Add Team</ModalHeader>
                <ModalBody>
                    {selectedImage ? (
                        <div className="image-preview mb-3" style={{ position: 'relative', display: 'flex', justifyContent: "center", }}>
                            <img src={selectedImage} alt="Selected" />
                            <FaTimes
                                className="delete-image-icon"
                                onClick={handleImageDelete}
                            />
                        </div>
                    ) : (
                        <div className="team-image-main mb-3">
                            <div className="upload-placeholder" style={{ textAlign: 'center' }}>
                                <label htmlFor="team_photo" style={{ cursor: 'pointer' }}>
                                    <img src='/images/upload.png' alt="Upload" style={{ width: '100px', height: '100px' }} />
                                    <p>Drag and Drop files, or Browse</p>
                                    <p>Upload only an image up to 10MB</p>
                                </label>
                                <input
                                    id="team_photo"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                    )}

                    <div className="details-field mb-3">
                        <label htmlFor="team_name">
                            Add Team Name<span>(50 characters)</span>
                        </label>
                        <input
                            type="text"
                            id="team_name"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            maxLength="50"
                            autoComplete="off"
                            className="form-control"
                        />
                    </div>

                    <Button type="button" className="next-create-button" onClick={handleAddTeams}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Teams;


// import React, { useState } from 'react';
// import { Button, Modal, ModalBody, ModalHeader, Table } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { FaTimes } from "react-icons/fa";
// import * as Yup from 'yup';
// import './Create.css'; // Ensure the path is correct
// import { useDispatch, useSelector } from 'react-redux';
// import { setTeams } from '../../actions'; // Correct path to actions

// const Teams = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     // Retrieve teams from Redux store
//     const teams = useSelector((state) => state.teams);
//     console.log(teams, "teams");

//     const [teamsModal, setTeamsModal] = useState(false);
//     const [selectedImage, setSelectedImage] = useState(null); // For displaying selected image

//     const schema = Yup.object().shape({
//         team_name: Yup.string().required('Team Name is required'),
//     });

//     const handleAddTeams = (values) => {
//         const updatedTeams = [...teams, values]; // Append new team
//         console.log("Dispatching teams Data:", updatedTeams);
//         dispatch(setTeams(updatedTeams)); // Dispatch updated teams
//     };

//     const handleDeleteRow = (index) => {
//         const updatedTeams = teams.filter((_, i) => i !== index); // Remove team at index
//         console.log("Dispatching teams Data after deletion:", updatedTeams);
//         dispatch(setTeams(updatedTeams)); // Dispatch updated teams
//     };

//     const handleImageChange = (e, setFieldValue) => {
//         const file = e.target.files[0];
//         if (file) {
//             const blobUrl = URL.createObjectURL(file); // Create blob URL
//             setSelectedImage(blobUrl); // Show selected image preview
//             setFieldValue('team_photo', blobUrl); // Store blob URL in Formik state
//         }
//     };

//     const handleImageDelete = (setFieldValue) => {
//         setSelectedImage(null); // Clear selected image preview
//         setFieldValue('team_photo', ""); // Clear Formik image field
//     };

//     return (
//         <>
//             <div>
//                 <div className='species-button-group'>
//                     <div className='page-title'>Teams</div>
//                     <Button
//                         type="button"
//                         onClick={() => setTeamsModal(true)}
//                         className="add-species-button"
//                     >
//                         Add teams
//                     </Button>
//                 </div>
//                 <Table>
//                     <thead>
//                         <tr>
//                             <th>S.No</th>
//                             <th>Team Name</th>
//                             <th>Team Photo</th>
//                             <th>Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {teams && teams.length > 0 ? (
//                             teams.map((team, index) => (
//                                 <tr key={index}>
//                                     <td>{index + 1}</td>
//                                     <td>{team.team_name}</td>
//                                     <td><img src={team.team_photo} alt='' width="50" /></td>
//                                     <td>
//                                         <FaTimes onClick={() => handleDeleteRow(index)} style={{ cursor: 'pointer' }} />
//                                     </td>
//                                 </tr>
//                             ))
//                         ) : (
//                             <tr>
//                                 <td colSpan="4">No teams available</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </Table>
//                 <div className="species-button-group">
//                     <Button
//                         type="button"
//                         className="next-create-button"
//                         onClick={() => {
//                             localStorage.setItem("page", "species"); // Update 'page' in localStorage
//                             navigate('/user-login/create/species');
//                         }}
//                     >
//                         Back
//                     </Button>
//                     <Button
//                         type="button"
//                         className="next-create-button"
//                         onClick={() => {
//                             localStorage.setItem("page", "members"); // Update 'page' in localStorage
//                             navigate('/user-login/create/members');
//                         }}
//                     >
//                         Next
//                     </Button>
//                 </div>
//             </div>

//             <Modal
//                 show={teamsModal}
//                 onHide={() => setTeamsModal(false)}
//                 backdrop="static"
//                 centered
//             >
//                 <ModalHeader closeButton>Add teams</ModalHeader>
//                 <ModalBody>
//                     <Formik
//                         validationSchema={schema}
//                         initialValues={{
//                             team_name: "",
//                             team_photo: "",
//                         }}
//                         onSubmit={(values, { setSubmitting, resetForm }) => {
//                             handleAddTeams(values); // Handle adding teams
//                             setTeamsModal(false);
//                             resetForm();
//                             setSubmitting(false); // Moved setSubmitting after resetForm
//                         }}
//                     >
//                         {({ setFieldValue }) => (
//                             <Form className="mb-3">
//                                 {selectedImage ? (
//                                     <div className="image-preview mb-3">
//                                         <img src={selectedImage} alt="Selected" />
//                                         <FaTimes
//                                             className="delete-image-icon"
//                                             onClick={() => handleImageDelete(setFieldValue)}
//                                         />
//                                     </div>
//                                 ) : (
//                                     <div className='team-image-main mb-3'>
//                                         <label htmlFor="team_photo" className='center-flex'>
//                                             <img src='/images/upload.png' alt="Upload" />
//                                             <p>Drag and Drop files, or Browse</p>
//                                             <p>Upload only an image up to 10MB</p>
//                                         </label>
//                                         <input
//                                             id="team_photo"
//                                             name="team_photo"
//                                             type="file"
//                                             accept="image/*"
//                                             style={{ display: 'none' }}
//                                             onChange={(e) => handleImageChange(e, setFieldValue)}
//                                         />
//                                     </div>
//                                 )}
//                                 <div className="details-field mb-3">
//                                     <label htmlFor="team_name">Add Team Name<span>(50 characters)</span> </label>
//                                     <Field
//                                         type="text"
//                                         id="team_name"
//                                         name="team_name"
//                                         autoComplete="off"
//                                     />
//                                     <div className="error-wrapper mb-3">
//                                         <ErrorMessage name="team_name" component="div" className="error-message" />
//                                     </div>
//                                 </div>

//                                 <Button type="submit" className="next-create-button">
//                                     Submit
//                                 </Button>
//                             </Form>
//                         )}
//                     </Formik>
//                 </ModalBody>
//             </Modal>
//         </>
//     );
// };

// export default Teams;
