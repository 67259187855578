// src/store.js
// import { createStore } from 'redux';
// import reducer from './reducer';

// // Enable Redux DevTools Extension if available
// const store = createStore(
//   reducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );
import { createStore, applyMiddleware } from 'redux'; // Import createStore from redux
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage for persistence
import thunk from 'redux-thunk'; // Import thunk correctly
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer'; // Your root reducer

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage, // Use localStorage for persistence
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Create the Redux store with middleware and Redux DevTools
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)) // Middleware setup with redux-thunk and DevTools
);

// Create a persistor to persist the store
const persistor = persistStore(store);

export { store, persistor };
