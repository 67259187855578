import React, { useEffect, useState } from 'react';
import { Table, Dropdown, Modal, Button, Badge } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import appUrl from '../../appUrl';
import './TournamentDetails.css'

const ApprovalTable = () => {
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const AppUrl = appUrl()
    const navigate = useNavigate();
    const { tournament_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [statusModal, setStatusModal] = useState(false);
    const [newStatus, setNewStatus] = useState({
        new_status: "",
        species_id: ""
    })
    console.log(newStatus, "new")

    const Details = async () => {
        setLoading(true);
        try {
            const fishingToken = localStorage.getItem('token');
            const response = await fetch(`${AppUrl}/api/v1/user/fishes/detail?tournament_id=${tournament_id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.code === 200) {
                console.log("called")
                setDetails(data.data);
                setLoading(false);
            } else {
                console.error(data.message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const isFishReport = localStorage.getItem("report")
    
    useEffect(() => {
        Details();
    }, []);

    const updateStatus = async () => {
        setLoading(true);
        try {
            const fishingToken = localStorage.getItem('token');
            const fish_id = localStorage.getItem('fishId');
            const tournament_id = localStorage.getItem('tournament')
            const response = await fetch(`${AppUrl}/api/v1/user/fishes/status?tournament_id=${tournament_id}&fish_id=${fish_id}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newStatus)
            });
            const data = await response.json();
            if (data.code === 200) {
                console.log("data")
                NotificationManager.success(data.message)
                setStatusModal(false)
                localStorage.removeItem("fish_id")
                window.location.reload()
            } else {
                console.error('Failed to fetch details');
                NotificationManager.error(data.message)
                setStatusModal(false)
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    // const changeStatus = (eventKey, fish) => {
    //     setNewStatus(prev => ({
    //         ...prev,
    //         new_status: eventKey,
    //         species_name: fish.species_name
    //     }));
    // };

    const statusFormatter = (status) => {
        console.log(status, "status")
        switch (status) {
            case "accepted":
                return "green";
            case "pending":
                return "#ffc107";
            case "rejected":
                return "red";
            default:
                return "black";
        }
    };

    return (
        <>
            <NotificationContainer />
            <div>
                {details.map((team, index) => (
                    <div key={index} className='mb-5'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div><h4 className='team-name'>{team.team_name}</h4></div>
                            <div className='d-flex justify-content-around '>
                                <div><p className='totalPoints'>Total Points: {team.team_points}</p></div>
                                <div><p className='totalPoints'>Total Fish: {team.team_fishes}</p></div>
                            </div>
                        </div>
                        <Table className='points-table'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Angler</th>
                                    <th>Fish Image</th>
                                    <th>Fish Name</th>
                                    <th>Fish Weight</th>
                                    <th>Fish Length</th>
                                    <th>Fishing Style</th>
                                    <th>Points</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {team.caught_fishes.length > 0 ?
                                    team.caught_fishes.map((fish, fishIndex) => (
                                        <tr key={fishIndex}>
                                            <td>{fishIndex + 1}</td>
                                            <td>{fish.member_name}</td>
                                            <td>{fish.fish_photo !== "" ? (<img src={fish.fish_photo} alt='' />) : "-"}</td>
                                            <td>{fish.species_name}</td>
                                            <td>{fish.fish_weight}</td>
                                            <td>{fish.fish_length}</td>
                                            <td>{fish.fishing_style || "-"}</td>
                                            {/* <td>{fish.total_points !== 0 ? <p style={{ color: "green" }}>{fish.total_points}</p> : <p style={{ color: "#ffc107" }}>{fish.waiting_points}</p>}</td> */}
                                            <td style={{ color: statusFormatter(fish.status) }}>
                                                {fish.status === "accepted" ? fish.total_points :
                                                    fish.status === "pending" ? fish.waiting_points :
                                                        fish.status === "rejected" ? 0 : "-"}
                                            </td>
                                            {isFishReport &&
                                                <td>
                                                    {fish.status === "pending" ?
                                                        (<Dropdown>
                                                            <Dropdown.Toggle variant="warning" id="dropdown-status">
                                                                {fish.status}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item eventKey="accepted"
                                                                    onClick={() => {
                                                                        setNewStatus({ new_status: "accepted", species_id: fish.species_id, member_email: fish.member_email });
                                                                        localStorage.setItem("fishId", fish._id);
                                                                        setStatusModal(true)
                                                                    }}
                                                                >Accept
                                                                </Dropdown.Item>
                                                                <Dropdown.Item eventKey="rejected"
                                                                    onClick={() => {
                                                                        setNewStatus({ new_status: "rejected", species_id: fish.species_id, member_email: fish.member_email });
                                                                        localStorage.setItem("fishId", fish._id);
                                                                        setStatusModal(true)
                                                                    }}
                                                                >Reject</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>) :
                                                        (fish.status === "accepted" ? <Badge bg="success">{fish.status}</Badge> : <Badge bg="danger">{fish.status}</Badge>)
                                                    }
                                                </td>
                                            }
                                        </tr>
                                    )) :
                                    (<tr><td colSpan="9" className='no-fish'>No fish captured yet</td></tr>)}
                            </tbody>
                        </Table>
                    </div>
                ))}
            </div>

            <Modal
                show={statusModal}
                onHide={() => setStatusModal(false)}
                className='report-modal'
                backdrop="static">
                <Modal.Body className='logout-model'>
                    <p>Are you sure you want<br /> to change status?</p>
                    <div className='d-flex justify-content-center'>
                        <Button className='btn-confirm' onClick={updateStatus}>Confirm</Button>
                        <Button className='btn-cancel' onClick={() => setStatusModal(false)}  >Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ApprovalTable
