import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Modal, ModalBody, ModalHeader, Dropdown, Form } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import appUrl from '../../appUrl';
// import './Table.css'
import './TournamentDetails.css'
import { IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { FaTimes } from "react-icons/fa";
import * as Yup from 'yup';

const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const formatTime = (timeString) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(`2000-01-01T${timeString}`).toLocaleTimeString('en-US', options);
};

const TournamentDetails = () => {
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const AppUrl = appUrl()
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [currentItem, setCurrentItem] = useState(null);
    const [selectedType, setSelectedType] = useState([]);

    useEffect(() => {
        const edit = localStorage.getItem("isEditing")
        if (edit) {
            setIsEdit(true)
        }
    }, [])

    console.log(isEdit, "isEdit")
    console.log(details, "selectedP")

    // console.log(details.teams[0].team_name, "team")

    const Details = async () => {
        setLoading(true);
        console.log(id, "Apii")
        try {
            const fishingToken = localStorage.getItem('token');
            const response = await fetch(`${AppUrl}/api/v1/tournament/getTournament?id=${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.code === 200) {
                console.log("called")
                setDetails(data.data);
                setLoading(false);
            } else {
                console.error('Failed to fetch projects of the selected user');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        Details();
    }, []);

    function formatUserRole(teamMembersType) {
        return teamMembersType.map(role => role.replace(/_/g, ' ')).join(', ');
    }

    const handleEditClick = (item) => {
        setCurrentItem(item); // Set the current species for editing
        setSelectedType(item.team_members_type); // Set current types
        setEditModal(true); // Show the edit modal
    };

    const handleUpdateItem = async (values) => {
        const fishingToken = localStorage.getItem('token');
        try {
            const response = await fetch(`${AppUrl}/api/v1/tournament/updateSpecies`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${fishingToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: currentItem._id, // Use the species ID from state
                    ...values,
                }),
            });
            const data = await response.json();
            if (data.code === 200) {
                NotificationManager.success('Species updated successfully');
                setEditModal(false);
                Details(); // Refresh details after update
            } else {
                NotificationManager.error('Failed to update species');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleOptionToggle = (option, setFieldValue) => {
        const newSelectedTypes = selectedType.includes(option)
            ? selectedType.filter(type => type !== option)
            : [...selectedType, option];

        setSelectedType(newSelectedTypes);
        setFieldValue('team_members_type', newSelectedTypes); // Update Formik value
    };


    return (
        <>
            <NotificationContainer />

            <div className='details'>
                <div className=" mb-4">
                    <IoMdArrowBack onClick={() => {
                        navigate(`/user-login/publish`);
                        localStorage.removeItem("isEditing");
                        localStorage.removeItem("tournament");
                    }} />
                </div>

                <div className='details-card mb-5'>
                    <div className='details-head d-flex'>
                        <h3>{details.tournament_name}</h3>
                        {isEdit && <Button
                            onClick={() => {
                                // localStorage.setItem("id", species._id);
                                localStorage.setItem("toEdit", "details");
                                setEditModal(true)
                            }
                            } style={{ cursor: 'pointer' }} className='edit-button' >
                            Edit
                        </Button>}
                    </div>
                    <hr />
                    <div>
                        <p><CiCalendarDate />{formatDate(details.start_date)} to {formatDate(details.end_date)}</p>
                    </div>
                    <div>
                        <p><IoMdTime />{formatTime(details.lines_in)} to {formatTime(details.lines_out)}</p>
                    </div>
                </div>

                <div className='details-title mb-3'>Setup</div>
                <div>
                    <div>
                        <Row className='checkbox-table'>
                            <Col>
                                <div className='selected'>
                                    Sound Horns at Lines in/Lines Out
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.sound_horns}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Are images required?
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.image_required}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Is picture Geo location required?
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.geolocation_required}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Catch must be verified
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.catch_verified}
                                        readOnly
                                    />
                                </div>
                                <div className='selected'>
                                    Hold Tournament Results Each Day
                                    <input
                                        type='checkbox'
                                        checked={details.set_up?.hold_tournament}
                                        readOnly
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className='multipliers'>
                        <Table>
                            <thead className='multiplier-head'>
                                <tr>
                                    <th>S.No</th>
                                    <th>Fishing style</th>
                                    <th>Multipliers</th>
                                    <th>Points</th>
                                    <th>Length</th>
                                    <th>Weight</th>
                                    {isEdit && <th>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {details.set_up?.tackle_multiplier && details.set_up.tackle_multiplier.length > 0 ? (
                                    details.set_up.tackle_multiplier.map((tackle, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{tackle.fishing_style}</td>
                                            <td>{tackle.multipliers}</td>
                                            <td><input type='checkbox' checked={details.points} readOnly /></td>
                                            <td><input type='checkbox' checked={details.length} readOnly /></td>
                                            <td><input type='checkbox' checked={details.weight} readOnly /></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">-</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className='details-title'>Species</div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Species Name</th>
                            <th>Points</th>
                            <th>Min Weight</th>
                            <th>Min Length</th>
                            <th>Daily Limit</th>
                            <th>Tournament Limit</th>
                            {isEdit && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {details.species && details.species.length > 0 ? (
                            details.species.map((species, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{species.speciesName}</td>
                                    <td>{species.speciesPoints}</td>
                                    <td>{species.min_weight}</td>
                                    <td>{species.min_length}</td>
                                    <td id='limit'>{species.daily_limit}</td>
                                    <td id='limit'>{species.tournament_limit}</td>
                                    {isEdit && (
                                        <td>
                                            <Button
                                                onClick={() => { handleEditClick(species); localStorage.setItem("toEdit", "species"); }} // Pass species to the edit function
                                                className='edit-button'>
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    localStorage.setItem("id", species._id);
                                                    localStorage.setItem("toEdit", "species");
                                                    setDeleteModal(true);
                                                }}
                                                className='delete-button'>
                                                Delete
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No species available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className='details-title'>Teams</div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Team Photo</th>
                            <th>Team Name</th>
                            <th>No. of Participants</th>
                            {isEdit && <th>Action</th>}
                            {/* <th>No. of Guide</th>
                            <th>Angler</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {details.teams && details.teams.length > 0 ? (
                            details.teams.map((team, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><img src={team.team_photo} alt='team' /></td>
                                    <td>{team.team_name}</td>
                                    <td>{team.speciesPoints}</td>
                                    {isEdit &&
                                        (<td>
                                            <Button
                                                onClick={() => {
                                                    handleEditClick(team);
                                                    localStorage.setItem("toEdit", "team");

                                                }}
                                                style={{ cursor: 'pointer' }} className='edit-button' >
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    localStorage.setItem("id", team._id);
                                                    localStorage.setItem("toEdit", "team");
                                                    setDeleteModal(true)
                                                }}
                                                className='delete-button'>
                                                Delete
                                            </Button>
                                        </td>)
                                    }
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No teams available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className='details-title'>Participants</div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Participant Name</th>
                            <th>Participant Role</th>
                            <th>Team</th>
                            <th>Email</th>
                            {isEdit && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {details.team_members && details.team_members.length > 0 ? (
                            details.team_members.map((members, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{members.team_members_name}</td>
                                    <td>{formatUserRole(members.team_members_type)}</td>
                                    <td>{members.select_team && members.select_team[0]}</td>
                                    <td style={{ textTransform: "lowercase" }}>{members.team_members_email}</td>
                                    {isEdit &&
                                        (<td>
                                            <Button
                                                onClick={() => {
                                                    handleEditClick(members);
                                                    localStorage.setItem("toEdit", "participant");

                                                }}
                                                style={{ cursor: 'pointer' }} className='edit-button' >
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    localStorage.setItem("id", members._id);
                                                    localStorage.setItem("toEdit", "participant");
                                                    setDeleteModal(true)
                                                }}
                                                className='delete-button'>
                                                Delete
                                            </Button>
                                        </td>)
                                    }
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No participants available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <div className='details-title'>Tournament Rules</div>
                <div className='rules'><li>{details.tournament_rules}</li></div>
            </div>

            {deleteModal && (
                <Modal
                    show={deleteModal}
                    onHide={() => setDeleteModal(false)}
                    centered
                    backdrop="static"
                    className="submission-modal"

                >
                    {(() => {
                        const item = localStorage.getItem("toEdit");
                        return (
                            <>
                                <ModalHeader className="publish-title">
                                    Are you sure you want to delete this {item}?
                                </ModalHeader>
                                <ModalBody>
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            className="publish-later"
                                            onClick={() => {
                                                setDeleteModal(false);
                                                localStorage.removeItem("id");
                                                localStorage.removeItem("toEdit");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="publish-now"
                                            style={{ backgroundColor: "#d91818", borderColor: "#d91818" }}
                                        // onClick={handleDeleteNow} 
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </ModalBody>
                            </>
                        );
                    })()}
                </Modal>
            )}

            {editModal &&
                <Modal
                    show={editModal}
                    onHide={() => setEditModal(false)}
                    centered
                    backdrop="static"
                >
                    <ModalHeader closeButton></ModalHeader>
                    {(() => {
                        const item = localStorage.getItem("toEdit");
                        return (
                            <>
                                {item === "details" && <ModalBody>details</ModalBody>}
                                {item === "species" && <ModalBody>
                                    <Formik
                                        initialValues={{
                                            speciesName: currentItem ? currentItem.speciesName : '',
                                            speciesPoints: currentItem ? currentItem.speciesPoints : '',
                                            min_weight: currentItem ? currentItem.min_weight : '',
                                            min_length: currentItem ? currentItem.min_length : '',
                                            daily_limit: currentItem ? currentItem.daily_limit : '',
                                            tournament_limit: currentItem ? currentItem.tournament_limit : '',
                                        }}
                                        validationSchema={Yup.object({
                                            speciesName: Yup.string().required('Required'),
                                            speciesPoints: Yup.number().required('Required'),
                                            min_weight: Yup.number(),
                                            min_length: Yup.number(),
                                            daily_limit: Yup.number(),
                                            tournament_limit: Yup.number(),
                                        })}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            handleUpdateItem(values); // Call the update function
                                            setSubmitting(false);
                                            resetForm();
                                        }}>
                                        {({ setFieldValue }) => (
                                            <Form className="mb-3">
                                                <div className='species-field'>
                                                    <div className="species-name mb-3">
                                                        <label htmlFor="speciesName">Species Name<span style={{ color: 'red' }}>*</span></label>
                                                        <Field
                                                            type="text"
                                                            id="speciesName"
                                                            name="speciesName"
                                                            autoComplete="off"
                                                        />
                                                        <div className="error-wrapper mb-3">
                                                            <ErrorMessage name="speciesName" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="species-points mb-3">
                                                        <label htmlFor="speciesPoints">Points<span style={{ color: 'red' }}>*</span></label>
                                                        <Field
                                                            type="number"
                                                            id="speciesPoints"
                                                            name="speciesPoints"
                                                            autoComplete="off"
                                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                                        />
                                                        <div className="error-wrapper mb-3">
                                                            <ErrorMessage name="speciesPoints" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='details-half'>
                                                    <div className="details-field mb-3">
                                                        <label htmlFor="min_weight">Min Weight</label>
                                                        <Field
                                                            type="number"
                                                            id="min_weight"
                                                            name="min_weight"
                                                            autoComplete="off"
                                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                                        />
                                                        <div className="error-wrapper mb-3">
                                                            <ErrorMessage name="min_weight" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="details-field mb-3">
                                                        <label htmlFor="min_length">Min Length</label>
                                                        <Field
                                                            type="number"
                                                            id="min_length"
                                                            name="min_length"
                                                            autoComplete="off"
                                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                                        />
                                                        <div className="error-wrapper mb-3">
                                                            <ErrorMessage name="min_length" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='details-half'>
                                                    <div className="details-field mb-3">
                                                        <label htmlFor="daily_limit">Daily Limit</label>
                                                        <Field
                                                            type="number"
                                                            id="daily_limit"
                                                            name="daily_limit"
                                                            autoComplete="off"
                                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                                        />
                                                        <div className="error-wrapper mb-3">
                                                            <ErrorMessage name="daily_limit" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="details-field mb-3">
                                                        <label htmlFor="tournament_limit">Tournament Limit</label>
                                                        <Field
                                                            type="number"
                                                            id="tournament_limit"
                                                            name="tournament_limit"
                                                            autoComplete="off"
                                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                                        />
                                                        <div className="error-wrapper mb-3">
                                                            <ErrorMessage name="tournament_limit" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <Button type="submit" className="submit-button">Update</Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik></ModalBody>}


                                {item === "team" && <ModalBody>teams</ModalBody>}


                                {item === "participant" && <ModalBody>

                                    <Formik
                                        initialValues={{
                                            team_members_name: currentItem ? currentItem.team_members_name : '',
                                            team_member_photo: currentItem ? currentItem.team_member_photo : '',
                                            team_members_email: currentItem ? currentItem.team_members_email : '',
                                            team_members_type: currentItem ? currentItem.team_members_type : [],
                                            team_id: currentItem ? currentItem.team_id : '',
                                        }}
                                        validationSchema={Yup.object({
                                            team_members_name: Yup.string().required('Participant name is required'),
                                            team_members_email: Yup.string().email('Invalid email').required('Participant email is required'),
                                            team_members_type: Yup.array().min(1, 'Select at least one participant type').required('Participant type is required'),
                                        })}
                                        onSubmit={(values, { setSubmitting, resetForm }) => {
                                            handleUpdateItem(values); // Call the update function
                                            setSubmitting(false);
                                            resetForm();
                                        }}>
                                        {({ setFieldValue }) => (
                                            <FormikForm className="mb-3">
                                                <div className="details-field mb-3">
                                                    <label htmlFor="team_members_name">Participant Name <span style={{ color: 'red' }}>*</span></label>
                                                    <Field
                                                        type="text"
                                                        id="team_members_name"
                                                        name="team_members_name"
                                                        autoComplete="off"
                                                        maxLength="50" // Enforce max length
                                                        className="form-control"
                                                    />
                                                    <div className="error-wrapper mb-3">
                                                        <ErrorMessage name="team_members_name" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                <div className="details-field mb-3">
                                                    <label htmlFor="team_members_email">E-mail ID <span style={{ color: 'red' }}>*</span></label>
                                                    <Field
                                                        type="email" // Use email input type for better validation
                                                        id="team_members_email"
                                                        name="team_members_email"
                                                        autoComplete="off"
                                                        className="form-control"
                                                    />
                                                    <div className="error-wrapper mb-3">
                                                        <ErrorMessage name="team_members_email" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                <div className="details-field mb-3">
                                                    <label htmlFor="team_id">Select Team</label>
                                                    <Field as="select" name="team_id" id="team_id" className="form-control">
                                                        <option value="">Select a team</option>
                                                        {details?.teams.map((team) => (
                                                            <option key={team._id} value={team._id}>
                                                                {team.team_name}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <div className="error-wrapper mb-3">
                                                        <ErrorMessage name="team_id" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                {/* Participant Type Field */}
                                                <div className="details-field mb-3">
                                                    <label htmlFor="team_members_type">Participant Type <span style={{ color: 'red' }}>*</span></label>

                                                    <Dropdown className='select-type-dropdown' style={{ width: '100%' }}>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '100%' }}>
                                                            {selectedType.length > 0 ? selectedType.join(', ') : 'Select Participant Type'}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{ width: '100%' }}>
                                                            {['guide', 'angler', 'tournament_assistant', 'spectator'].map(type => (
                                                                <Dropdown.Item as="div" key={type}>
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label={type.replace(/_/g, ' ')}
                                                                        checked={selectedType.includes(type)}
                                                                        onChange={() => handleOptionToggle(type, setFieldValue)}
                                                                    />
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                    <div className="error-wrapper mb-3">
                                                        <ErrorMessage name="team_members_type" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                <Button className='next-create-button' type="submit">
                                                    Update
                                                </Button>
                                            </FormikForm>
                                        )}
                                    </Formik>
                                </ModalBody>}
                            </>
                        );
                    })()}
                </Modal>
            }
        </>
    )
};

export default TournamentDetails;


