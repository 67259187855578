// src/components/Submission.js
import React, { useEffect, useState } from 'react';
import { Button, Spinner, Alert, Modal, ModalHeader, ModalBody } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { submitTournament ,CLEAR_STATE } from '../../actions';
import { SlCalender } from "react-icons/sl";
import { PiClockBold } from "react-icons/pi";
import appUrl from '../../appUrl';

const Submission = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AppUrl = appUrl();
  const [publishModal, setPublishModal] = useState(false);
  console.log(publishModal, "publishModal")

  // Retrieve all necessary data and submission state from Redux store
  const setup = useSelector((state) => state.setup);
  const species = useSelector((state) => state.species);
  const members = useSelector((state) => state.members);
  const rules = useSelector((state) => state.rules);
  // const teams = useSelector((state) => state.teams); // Uncomment if needed

  const submitting = useSelector((state) => state.submitting);
  const submitSuccess = useSelector((state) => state.submitSuccess);
  const submitError = useSelector((state) => state.submitError);

  // Retrieve 'details' from localStorage
  const allDetailsRaw = localStorage.getItem("details");
  let allDetails = null;
  try {
    allDetails = allDetailsRaw ? JSON.parse(allDetailsRaw) : null;
  } catch (error) {
    console.error("Error parsing 'details' from localStorage:", error);
    allDetails = null;
  }

  // useEffect(() => {
  //   if (submitSuccess) {
  //     console.log("Setting publishModal to true");
  //     setPublishModal(true);
  //   }
  // }, [submitSuccess]);

  // In your component after a successful submission:
  useEffect(() => {
    if (submitSuccess) {
        setPublishModal(true);
        const timer = setTimeout(() => {
            dispatch({ type: CLEAR_STATE }); // Dispatch CLEAR_STATE after 7 seconds
        }, 2000); // Adjust delay as necessary
        return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [submitSuccess]);
  

  useEffect(() => {
    console.log("Current publishModal state:", publishModal);
  }, [publishModal]);


  // Effect to check if 'allDetails' exists
  useEffect(() => {
    if (!allDetails) {
      // Inform the user and redirect to the creation page
      alert("No tournament details found. Redirecting to the creation page.");
      navigate("/user-login/create");
    }
  }, [allDetails, navigate]);

  // Function to handle submission
  const handleSubmit = () => {
    console.log("Submitting tournament...");
    dispatch(submitTournament());
  };

  // Function to format date in 'MMM D, YYYY' format
  function formatDate(dateString) {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid Date';
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  }

  const formattedStartDate = formatDate(allDetails?.start_date);
  const formattedEndDate = formatDate(allDetails?.end_date);

  // Function to handle "Publish Later"
  const handleLater = () => {
    localStorage.removeItem("details");
    localStorage.removeItem("tournament");
    localStorage.removeItem("page");
    navigate("/user-login/create");
  };

  // Function to handle "Publish Now"
  const handlePublishNow = async () => {
    try {
      const fishingToken = localStorage.getItem("token");
      const tournament = localStorage.getItem("tournament");

      if (!fishingToken || !tournament) {
        alert("Authentication token or Tournament ID is missing.");
        return;
      }

      const response = await fetch(`${AppUrl}/api/v1/tournament/publish?tournamentId=${tournament}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ publish: true }) // Changed "true" to boolean true
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Tournament published successfully:", data);
        localStorage.removeItem("details");
        localStorage.removeItem("tournament");
        localStorage.removeItem("page");
        navigate("/user-login/create");
      } else {
        console.error("Failed to publish tournament:", data);
        alert(`Error: ${data.message || 'Failed to publish tournament'}`);
      }
    } catch (error) {
      console.error("Error publishing tournament:", error);
      alert(`Error: ${error.message || 'Failed to publish tournament'}`);
    }
  };

  // If 'allDetails' is missing, the component will redirect, so no need to render the rest
  if (!allDetails) {
    return null; // Or a loader/spinner if preferred
  }

  return (
    <div className="submission-page container mt-5">
      {/* <h2>Submit Tournament</h2> */}

      <div className='submission-main mb-3'>
        <div className='submission-sub' >
          <h2>{allDetails.tournament_name || 'Untitled Tournament'}</h2>
          <hr className="custom-hr" />
          <div>
            <p><SlCalender /> {formattedStartDate} to {formattedEndDate}</p>
          </div>
          <div>
            <p><PiClockBold /> {allDetails.lines_in || 'N/A'} to {allDetails.lines_out || 'N/A'}</p>
          </div>
          <Button variant="primary" onClick={handleSubmit} disabled={submitting}>
            {submitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Submitting...
              </>
            ) : (
              'Submit Tournament'
            )}
          </Button>
        </div>
      </div>
      <Button
        type="button"
        className="back-create-button"
        onClick={() => {
          // Update 'page' in localStorage
          localStorage.setItem("page", "rules");
          navigate('/user-login/create/rules');
        }}
      >
        Back
      </Button>
      {publishModal &&
        <Modal
          show={publishModal}
          onHide={() => setPublishModal(false)}
          backdrop="static"
          centered
          className='submission-modal'
        >
          <ModalHeader closeButton></ModalHeader>
          <ModalBody>
            <h3 className='publish-title'>Are you sure you want to publish the tournament?</h3>
            <div className="d-flex justify-content-center mt-3">
              <Button className='publish-later' onClick={handleLater}>
                Publish Later
              </Button>
              <Button className='publish-now' onClick={handlePublishNow}>
                Publish Now
              </Button>
            </div>
          </ModalBody>
        </Modal>
      }
      {submitError && <Alert variant="danger" className="mt-3">{submitError}</Alert>}
      {/* Optionally, display a summary of the data */}
      {/* <div className="mt-4">
        <h4>Review Your Tournament Data</h4>
        <pre>{JSON.stringify({ setup, species, members, rules }, null, 2)}</pre>
      </div> */}
    </div>
  );
};

export default Submission;
