// src/components/Rules.js
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Create.css'; // Ensure the path is correct
import { useDispatch, useSelector } from 'react-redux';
import { setRules } from '../../actions'; // Ensure this is the correct path to your actions

const Rules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const rules = useSelector((state) => state.rules); // Now a string

  const handleSetRules = (values) => {
    console.log("Dispatching Rules Data:", values.tournament_rules);
    dispatch(setRules(values.tournament_rules));
  };

  const validationSchema = Yup.object({
    tournament_rules: Yup.string().required('Tournament rules are required'),
  });

  return (
    <div>
      <div className='page-title'>Rules</div>

      <Formik
        initialValues={{
          tournament_rules: rules || ""
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSetRules(values);
          setSubmitting(false);
          // Navigate to the next page after dispatching
          localStorage.setItem("page", "submission"); // Update 'page' in localStorage
          navigate('/user-login/create/submission');
        }}
      >
        {({ isSubmitting }) => (
          <FormikForm className="mb-3">
            <div className="rules-main mb-3">
              <Field
                as="textarea"
                id="tournament_rules"
                name="tournament_rules"
                autoComplete="off"
                rows={8}
                style={{ width: '100%' }} // Adjust as needed for styling
                placeholder="Enter tournament rules here" // Added placeholder
              />
              <div className="error-wrapper mb-3">
                <ErrorMessage name="tournament_rules" component="div" className="error-message" />
              </div>
            </div>
            <div className="species-button-group">
              <Button
                type="button"
                className="next-create-button"
                onClick={() => {
                  localStorage.setItem("page", "members"); // Update 'page' in localStorage
                  navigate('/user-login/create/members');
                }}
              >
                Back
              </Button>
              <Button
                type="submit" // Changed to submit
                className="next-create-button"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default Rules;

