import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import appUrl from '../../appUrl';
import { IoSearch } from "react-icons/io5";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../../Components/Table/Table.css';

const ViewReport = () => {
    const AppUrl = appUrl();
    const navigate = useNavigate();

    const [isStatic, setIsStatic] = useState(false);
    const [fetched, setFetched] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [projects, setProjects] = useState([]);  // Use this to show in table
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const handleGetAllReports = async () => {
        setDataLoading(true);
        const token = localStorage.getItem("token");
        const type = isStatic ? 'static' : 'public';
        try {
            const response = await fetch(`${AppUrl}/api/v1/report/all?type=${type}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
            const data = await response.json();
            if (data.status === true) {
                setFetched(data.data);
                setProjects(data.data);  // Set projects to the fetched data for display
                setTotalCount(data.data.length);
                setDataLoading(false);
            } else {
                NotificationManager.error("Failed to fetch data");
                setDataLoading(false);
            }
        } catch (error) {
            console.log("Error fetching reports:", error);
            NotificationManager.error("Error fetching reports");
            setDataLoading(false);
        }
    };

    const handlePublic = () => setIsStatic(false);
    const handleStatic = () => setIsStatic(true);

    useEffect(() => {
        handleGetAllReports();
    }, [isStatic]);

    const handlePageChange = (page, sizePerPage) => {
        setCurrentPage(page);
        setPageSize(sizePerPage);
    };

    const columns = isStatic
        ? [
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
            },
            {
                dataField: 'tournament_name',
                text: 'Tournament Name',
                filter: textFilter({ placeholder: '' }),
            },
            // {
            //     dataField: 'createdAt',
            //     text: 'Created On',
            //     filter: textFilter({ placeholder: '' }),
            // },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (cell, row) => (
                    <>
                        <Button className='status-button-column' 
                        onClick={() => console.log("Public report action", row)} >
                            View User Report
                        </Button>
                        <Button className='status-button-column' 
                        onClick={() => {
                            navigate(`/user-login/active/fishes/${row._id}`);
                            localStorage.setItem("tournament", row._id);
                        }}>
                            View Fish Report
                        </Button>
                    </>
                ),
            }
        ]
        : [
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
            },
            {
                dataField: 'report_name',
                text: 'Award',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'first',
                text: 'First',
                filter: textFilter({ placeholder: '' })
            },
            {
                dataField: 'second',
                text: 'Second',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'third',
                text: 'Third',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: (cell, row) => (
                    <> <Button onClick={() => {
                        navigate("/user-login/publish/result");
                        localStorage.setItem("report", row._id)
                    }} className='status-button-column'>
                        View Result
                    </Button>
                    </>
                ),
            }
        ];

    return (
        <>
            <NotificationContainer />
            <div className="d-flex justify-content-between align-items-center my-3">
                <div className="d-flex my-3 publish-title">
                    <Button variant={!isStatic ? "selected" : ""} onClick={handlePublic}>
                        Public
                    </Button>
                    <Button variant={isStatic ? "selected" : ""} onClick={handleStatic} className="ml-2">
                        Static
                    </Button>
                </div>
                <div className='search-main'><IoSearch /> Search</div>
            </div>

            {dataLoading ? (
                <div className='loading-spinner'>
                    <Spinner animation="border" role="status" />
                </div>
            ) : (
                <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={projects}
                    columns={columns}
                    filter={filterFactory()}
                    pagination={paginationFactory({
                        page: currentPage,
                        sizePerPage: pageSize,
                        totalSize: totalCount,
                        onPageChange: handlePageChange,
                        sizePerPageRenderer: () => null,
                        showTotal: true,
                        paginationTotalRenderer: (from, to, total) => (
                            <div className="pagination-and-entries">
                                <p className="entries">
                                    Showing {from} to {to} of {total} entries
                                </p>
                            </div>
                        ),
                    })}
                />
            )}
        </>
    );
};

export default ViewReport;
