// src/Components/Create/SetUp.jsx
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { FaPlus, FaTimes } from "react-icons/fa";
import 'react-toggle/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setUp } from '../../actions';
import { useNavigate } from 'react-router-dom';
import './Create.css'; // Ensure the path is correct

const SetUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Retrieve existing setup data from Redux store
  const existingSetup = useSelector((state) => state.setup);

  // Initialize setup state with existing data or default values
  const [setup, setSetup] = useState({
    sound_horns: existingSetup.sound_horns || false,
    image_required: existingSetup.image_required || false,
    geolocation_required: existingSetup.geolocation_required || false,
    catch_verified: existingSetup.catch_verified || false,
    hold_tournament: existingSetup.hold_tournament || false,
    result_time: existingSetup.result_time || false,
    result_time_value: existingSetup.result_time_value || '',
    tackle_multiplier: existingSetup.tackle_multiplier || [] // Initialize as empty array
  });

  // Handler to update boolean fields in setup
  const handleToggleChange = (name, value) => {
    if (name === 'tackle_multiplier') {
      if (value) {
        // If toggled on and no multipliers exist, add an initial empty multiplier
        setSetup(prevSetup => ({
          ...prevSetup,
          tackle_multiplier: prevSetup.tackle_multiplier.length > 0 ? prevSetup.tackle_multiplier : [{
            fishing_style: "",
            multipliers: "",
            points: false,
            length: false,
            weight: false
          }]
        }));
      } else {
        // If toggled off, clear all multipliers
        setSetup(prevSetup => ({ ...prevSetup, tackle_multiplier: [] }));
      }
    } else {
      setSetup(prevSetup => ({ ...prevSetup, [name]: value }));
    }
  };

  // Handler to add a new multiplier row
  const handleAddRow = () => {
    setSetup(prevSetup => ({
      ...prevSetup,
      tackle_multiplier: [
        ...prevSetup.tackle_multiplier,
        {
          fishing_style: "",
          multipliers: "",
          points: false,
          length: false,
          weight: false
        }
      ]
    }));
  };

  // Handler to delete a multiplier row by index
  const handleDeleteRow = (index) => {
    setSetup(prevSetup => ({
      ...prevSetup,
      tackle_multiplier: prevSetup.tackle_multiplier.filter((_, i) => i !== index)
    }));
  };

  // Handler to update fields within a multiplier row
  const handleMultiplierChange = (index, field, value) => {
    setSetup(prevSetup => {
      const updatedMultiplier = [...prevSetup.tackle_multiplier];
      updatedMultiplier[index][field] = value;
      return { ...prevSetup, tackle_multiplier: updatedMultiplier };
    });
  };

  // Handler for the Next button
  const handleSetupNext = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Log the setup data
    console.log("Dispatching Setup Data:", setup);
    // Dispatch the setup data to Redux store
    dispatch(setUp(setup));

    // Update the current page in localStorage
    localStorage.setItem("page", "/user-login/create/species");

    // Navigate to the next page
    navigate('/user-login/create/species');
  };

  return (
    <>
      <div className='setup-main'>

        <h4>Setup</h4>

        <form onSubmit={handleSetupNext}>

          <div className='setup-half'>
            <p>Sound Horns at Lines In/Lines Out</p>
            <Toggle
              checked={setup.sound_horns}
              onChange={(e) => handleToggleChange('sound_horns', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Are images required?</p>
            <Toggle
              checked={setup.image_required}
              onChange={(e) => handleToggleChange('image_required', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Is picture Geo location required?</p>
            <Toggle
              checked={setup.geolocation_required}
              onChange={(e) => handleToggleChange('geolocation_required', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Catch must be verified</p>
            <Toggle
              checked={setup.catch_verified}
              onChange={(e) => handleToggleChange('catch_verified', e.target.checked)}
            />
          </div>

          <div className='setup-half'>
            <p>Hold tournament Results Each day</p>
            <Toggle
              checked={setup.result_time}
              onChange={(e) => handleToggleChange('result_time', e.target.checked)}
            />
          </div>

          {setup.result_time && (
            <div className='setup-half'>
              <p className='result-time'>Select result time</p>
              {/* Replace with your preferred time picker component */}
              <input
                type='time'
                value={setup.result_time_value}
                onChange={(e) => setSetup(prev => ({ ...prev, result_time_value: e.target.value }))}
                required
              />
            </div>
          )}

          <div className='setup-half'>
            <p>Are there tackle multipliers?</p>
            <Toggle
              checked={setup.tackle_multiplier.length > 0}
              onChange={(e) => handleToggleChange('tackle_multiplier', e.target.checked)}
            />
          </div>

          {setup.tackle_multiplier.length > 0 && (
            <div className='multipliers mb-3'>
              <table>
                <thead className='multiplier-head'>
                  <tr>
                    <th>Fishing Style</th>
                    <th>Multipliers</th>
                    <th>Points</th>
                    <th>Length</th>
                    <th>Weight</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {setup.tackle_multiplier.map((mult, index) => (
                    <tr className='mb-3' key={index}>
                      <td>
                        <input
                          type='text'
                          value={mult.fishing_style}
                          onChange={(e) => handleMultiplierChange(index, 'fishing_style', e.target.value)}
                          required
                        />
                      </td>
                      <td>
                        <input
                          type='number'
                          value={mult.multipliers}
                          onChange={(e) => handleMultiplierChange(index, 'multipliers', e.target.value)}
                          required
                          min="0"
                          step="0.01"
                        />
                      </td>
                      <td>
                        <input
                          type='checkbox'
                          className='setup-checkbox'
                          checked={mult.points}
                          onChange={(e) => handleMultiplierChange(index, 'points', e.target.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type='checkbox'
                          className='setup-checkbox'
                          checked={mult.length}
                          onChange={(e) => handleMultiplierChange(index, 'length', e.target.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type='checkbox'
                          className='setup-checkbox'
                          checked={mult.weight}
                          onChange={(e) => handleMultiplierChange(index, 'weight', e.target.checked)}
                        />
                      </td>
                      <td>
                        <FaTimes onClick={() => handleDeleteRow(index)} style={{ cursor: 'pointer' }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button type='button' onClick={handleAddRow} className='add-row-button'>
                <FaPlus /> Add
              </Button>
            </div>
          )}

          <div className='button-group'>
            <Button type='button' className='back-create-button' onClick={() => {
              localStorage.setItem("page", "");
              localStorage.setItem("isUpdate", "true");
              navigate('/user-login/create/');
            }}>
              Back
            </Button>
            <Button type='submit' className='next-create-button'>Next</Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SetUp;
