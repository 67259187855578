import React, { useState, useEffect } from 'react';
import { Button,Nav} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { BallTriangle } from 'react-loader-spinner';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { SlCalender } from "react-icons/sl";
import { PiClockBold } from "react-icons/pi";
import { CFormInput, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';
import appUrl from '../../appUrl';
import 'react-datepicker/dist/react-datepicker.css';
import './Create.css';

const Name = () => {
  const AppUrl = appUrl();
  const navigate = useNavigate();

  const [showLinesInTime, setShowLinesInTime] = useState(false);
  const [showLinesOutTime, setShowLinesOutTime] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(Intl.DateTimeFormat().resolvedOptions().timeZone,"timezone")

  const schema = Yup.object().shape({
    tournament_name: Yup.string().required('Tournament Name is required'),
    start_date: Yup.date()
      .required('Start date is required'),
    end_date: Yup.date()
      .min(Yup.ref('start_date'), 'End date must be greater than start date')
      .required('End date is required'),
    lines_in: Yup.string().required('Lines in is required'),
    lines_out: Yup.string().required('Lines out is required'),
  });

  const handleDetailsSubmission = async (values) =>{
    setLoading(true);
    try {
      const fishingToken = localStorage.getItem('token');
      const response = await fetch(`${AppUrl}/api/v1/tournament`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(formatSubmissionDates(values))
        body: JSON.stringify({
          ...formatSubmissionDates(values),
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamically capture timezone
        })
      });
      const data = await response.json();
      if (response.ok && data.status === true) {
        setLoading(false);
        // NotificationManager.success(data.message);
        localStorage.setItem("details", JSON.stringify(values));
        localStorage.setItem("tournament", data.tournament_id);
        localStorage.setItem("page", "setup");
        navigate("/user-login/create/setup");
      } else {
        setLoading(false);
        NotificationManager.error(data.message || 'Failed to create tournament.');
        console.error('Error:', data.message);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error(error.message || 'An unexpected error occurred.');
      console.error('Error:', error);
    }
  };

  const handleEditSubmission = async (values) => {
    setLoading(true);
    try {
      const fishingToken = localStorage.getItem('token');
      const tournament_id = localStorage.getItem('tournament');

      if (!tournament_id) {
        throw new Error('Tournament ID is missing.');
      }
      const response = await fetch(`${AppUrl}/api/v1/tournament/${tournament_id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${fishingToken}`,
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(formatSubmissionDates(values))
        body: JSON.stringify({
          ...formatSubmissionDates(values),
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone // Dynamically capture timezone
        })
      });
      const data = await response.json();
      if (response.ok && data.status === true) {
        setLoading(false);
        // NotificationManager.success(data.message);
        localStorage.removeItem("isUpdate");
        localStorage.setItem("details", JSON.stringify(values));
        localStorage.setItem("page", "setup");
        navigate("/user-login/create/setup");
      } else {
        setLoading(false);
        NotificationManager.error(data.message || 'Failed to update tournament.');
        console.error('Error:', data.message);
      }
    } catch (error) {
      setLoading(false);
      NotificationManager.error(error.message || 'An unexpected error occurred.');
      console.error('Error:', error);
    }
  };

  const isUpdate = localStorage.getItem("isUpdate");

  // Function to parse date strings from localStorage into Date objects
  const parseInitialDates = (details) => {
    return {
      ...details,
      start_date: details.start_date ? new Date(details.start_date) : null,
      end_date: details.end_date ? new Date(details.end_date) : null,
    };
  };

  // Optionally, load initial values from localStorage if available
  const initialDetails = isUpdate === "true" 
    ? parseInitialDates(JSON.parse(localStorage.getItem("details")) || {
        tournament_name: "",
        start_date: null,
        end_date: null,
        lines_in: "",
        lines_out: "",
      }) 
    : {
        tournament_name: "",
        start_date: null,
        end_date: null,
        lines_in: "",
        lines_out: ""
      };

  console.log(initialDetails.start_date, "startttttttttt");

  // Helper function to format dates before submission
  const formatSubmissionDates = (values) => {
    return {
      ...values,
      start_date: values.start_date ? formatDate(values.start_date) : '',
      end_date: values.end_date ? formatDate(values.end_date) : '',
    };
  };

  // Format date as YYYY-MM-DD to avoid timezone issues
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (`0${(date.getMonth() + 1)}`).slice(-2); // Months are zero indexed
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <NotificationContainer />
      <div>
        {loading && (
          <div className='loader-overlay'>
            <BallTriangle
              color="#00555C"
              height={80}
              width={80}
            />
          </div>
        )}
        <Formik
          validationSchema={schema}
          initialValues={initialDetails}
          enableReinitialize={true} // Allows Formik to update initialValues if initialDetails changes
          onSubmit={(values, { setSubmitting }) => {
            if (isUpdate === "true") {
              handleEditSubmission(values);
            } else {
              handleDetailsSubmission(values);
            }
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className='mb-3'>
              <div className='details-field mb-3'>
                <label htmlFor='tournament_name'>Tournament Name</label>
                <Field
                  type='text'
                  id='tournament_name'
                  name='tournament_name'
                  autoComplete='off'
                />
                <div className='error-wrapper mb-3'>
                  <ErrorMessage name='tournament_name' component='div' className='error' />
                </div>
              </div>

              <div className='details-half'>
                <div className='details-field mb-3'>
                  <label htmlFor='start_date'>Start date</label>
                  <div className="date-picker-wrapper">
                    <DatePicker
                      selected={values.start_date}
                      onChange={(date) => {
                        setFieldValue('start_date', date);
                        // Optionally, reset end_date if start_date changes
                        if (values.end_date && date && date > values.end_date) {
                          setFieldValue('end_date', null);
                        }
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select date"
                      showYearDropdown
                      minDate={new Date()}
                      scrollableYearDropdown
                      className="date-picker"
                    />
                    <SlCalender className="calendar-icon" />
                  </div>
                  <div className='error-wrapper mb-3'>
                    <ErrorMessage name='start_date' component='div' className='error' />
                  </div>
                </div>

                <div className='details-field mb-3'>
                  <label htmlFor='end_date'>End date</label>
                  <div className="date-picker-wrapper">
                    <DatePicker
                      selected={values.end_date}
                      onChange={(date) => {
                        setFieldValue('end_date', date);
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="Select date"
                      showYearDropdown
                      minDate={values.start_date || new Date()}
                      scrollableYearDropdown
                      className="date-picker"
                    />
                    <SlCalender className="calendar-icon" />
                  </div>
                  <div className='error-wrapper mb-3'>
                    <ErrorMessage name='end_date' component='div' className='error' />
                  </div>
                </div>
              </div>

              <div className='details-half'>
                <div className='details-field mb-3'>
                  <label htmlFor='lines_in'>Lines in</label>
                  <Field
                    type='text'
                    id='lines_in'
                    name='lines_in'
                    readOnly
                    onClick={() => setShowLinesInTime(true)}
                  />
                  <PiClockBold onClick={() => setShowLinesInTime(true)} className="clock-icon" />
                  <div className='error-wrapper mb-3'>
                    <ErrorMessage name='lines_in' component='div' className='error' />
                  </div>
                </div>

                <div className='details-field mb-3'>
                  <label htmlFor='lines_out'>Lines out</label>
                  <Field
                    type='text'
                    id='lines_out'
                    name='lines_out'
                    readOnly
                    onClick={() => setShowLinesOutTime(true)}
                  />
                  <PiClockBold onClick={() => setShowLinesOutTime(true)} className="clock-icon" />
                  <div className='error-wrapper mb-3'>
                    <ErrorMessage name='lines_out' component='div' className='error' />
                  </div>
                </div>
              </div>

              <Button type='submit' className='next-create-button'>Next</Button>

              {/* Lines In Time Modal */}
              <CModal visible={showLinesInTime} onClose={() => setShowLinesInTime(false)} backdrop="static">
                <CModalHeader>
                  <CModalTitle>Select Lines In Time</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <CFormInput
                    type='time'
                    id='lines_in'
                    name='lines_in'
                    className='mb-3'
                    value={values.lines_in}
                    onChange={(e) => setFieldValue('lines_in', e.target.value)}
                  />
                  <Button className='next-create-button' onClick={() => setShowLinesInTime(false)}>Submit</Button>
                </CModalBody>
              </CModal>

              {/* Lines Out Time Modal */}
              <CModal visible={showLinesOutTime} onClose={() => setShowLinesOutTime(false)}
                backdrop="static">
                <CModalHeader>
                  <CModalTitle>Select Lines Out Time</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <CFormInput
                    type='time'
                    id='lines_out'
                    name='lines_out'
                    className='mb-3'
                    value={values.lines_out}
                    onChange={(e) => setFieldValue('lines_out', e.target.value)}
                  />
                    <Button className='next-create-button' onClick={() => setShowLinesOutTime(false)}>Submit</Button>
                </CModalBody>
              </CModal>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Name;
